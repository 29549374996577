<template>
  <div>
    <div>
      <b-card>
        <modal-actions
          class="mb-3"
          @close="$router.push({ name: 'committee-inscription' })"
        />

        <div class="shdow">
          <div class="d-flex flex-wrap justify-content-between align-items-center">
            <p class="h2 text-secondary">Modifier une session</p>
            <b-button variant="secondary">Confirmer</b-button>
            <hr class="w-100" />
          </div>

          <div class="row">
            <div class="col-12 col-md-6">
              <b-form-group label-cols="4" label-cols-lg="3" label="Nom de session">
                <b-form-input type="text"></b-form-input>
              </b-form-group>
              <b-form-group label-cols="4" label-cols-lg="3" label="Date">
                <b-datepicker></b-datepicker>
              </b-form-group>
              <!-- <b-form-group label-cols="4" label-cols-lg="3" label="Comité">
              <multiselect
                :options="['Comité 1', 'Comité 2', 'Comité 3']"
                placeholder="Choisir une comité"
              ></multiselect>
            </b-form-group> -->
            </div>
            <div class="col-12 col-md-6">
              <b-form-group label-cols="4" label-cols-lg="3" label="Planning">
                <multiselect
                  multiple
                  :options="['Planning 1', 'Planning 2', 'Planning 3']"
                ></multiselect>
              </b-form-group>
              <!-- <b-form-group label-cols="4" label-cols-lg="3" label="Inscrits">
              <multiselect
                multiple
                :options="['Inscrit 1', 'Inscrit 2', 'Inscrit 3']"
              ></multiselect>
            </b-form-group> -->
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <b-tabs class="tab-solid tab-solid-primary mt-3">
              <div class="row mb-3">
                <div class="col-12 d-flex justify-content-end">
                  <b-button
                    variant="secondary"
                    class="align-btn-table"
                    @click="legalEntity.tableItems.push({ editMode: true })"
                    >Nouvelle inscription</b-button
                  >
                </div>
              </div>
              <b-tab title="Inscrits">
                <session-inscription-table :legalEntity="legalEntity" />
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import ModalActions from "@/components/custom/ModalActions.vue";
import SessionInscriptionTable from "../components/SessionInscriptionTable.vue";

export default {
  components: {
    SessionInscriptionTable,
    ModalActions,
  },
  data: () => ({
    legalEntity: {
      tableFields: [
        { key: "role", label: "Rôle", sortable: true },
        { key: "user", label: "Utilisateur", sortable: true },
        { key: "session", label: "Session", sortable: true },
        { key: "folder", label: "Dossier", sortable: true },
        { key: "date", label: "Date", sortable: true },
        { key: "actions", label: "", sortable: false },
      ],
      tableItems: [
        {
          role: "Présentateur",
          user: {
            name: "J.D",
            full_name: "John Doe",
            img: "https://source.unsplash.com/random/150x150",
          },
          session: "session 1",
          folder: "Dossier 1",
          date: "02-01-2021",
          actions: ["show", "edit", "delete"],
        },
      ],
    },
  }),
  methods: {},
};
</script>

<style></style>
